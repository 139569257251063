<template>
  <div :class="['grid__container', size ? 'grid__container--' + size : ' ' , 
                {'column--align-left':(align === 'left')},
                {'column--align-middle':(align === 'middle')},
                {'column--align-right':(align === 'right')},
                {'column--align-top':(align === 'top')},
                {'column--align-bottom':(align === 'bottom')},

                {'column--align-top-left':(align === 'top left')},
                {'column--align-top-middle':(align === 'top middle')},
                {'column--align-top-right':(align === 'top right')},

                {'column--align-middle-left':(align === 'middle left')},
                {'column--align-middle-right':(align === 'middle right')},

                {'column--align-bottom-left':(align === 'bottom left')},
                {'column--align-bottom-middle':(align === 'bottom middle')},
                {'column--align-bottom-right':(align === 'bottom right')}]" :id="id" name="grid__container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    size: String,
    align: String,
  },
  methods:{
    responsiveClasses: function() {
      let id = this.id;
      let div = document.getElementById(id);
      if(div !== null){
        let name = div.getAttribute('name');
        let options = [{prop : 'size', name : name},];
        options.forEach(this.classNames)
      }
    },
  },
  beforeMount(){
    this.id = this._uid
  },
  mounted(){
    this.responsiveClasses()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';

.grid__container{
  grid-column-start:auto;
  grid-column-end: span 12;
  max-width:100%;
}
.grid__container--12{
  grid-column-end: span 12;
}
.grid__container--11{
  grid-column-end: span 11;
}
.grid__container--10{
  grid-column-end: span 10;
}
.grid__container--9{
  grid-column-end: span 9;
}
.grid__container--8{
  grid-column-end: span 8;
}
.grid__container--7{
  grid-column-end: span 7;
}
.grid__container--6{
  grid-column-end: span 6;
}
.grid__container--5{
  grid-column-end: span 5;
}
.grid__container--4{
  grid-column-end: span 4;
}
.grid__container--3{
  grid-column-end: span 3;
}
.grid__container--2{
  grid-column-end: span 2;
}
.grid__container--1{
  grid-column-end: span 1;
}
.grid--col-auto .grid__container{
  grid-column-end: auto;
}

.column--align-left{
  align-self: start;
  justify-self: start;
}
.column--align-middle{
  align-self: center;
  justify-self: center;
}
.column--align-right{
  align-self: start;
  justify-self: end;
}
.column--align-top{
  align-self: start;
  justify-self: start;
}
.column--align-bottom{
  align-self: end;
  justify-self: start;
}
.column--align-top-left{
  align-self: start;
  justify-self: start;
}
.column--align-top-middle{
  align-self: start;
  justify-self: center;
}
.column--align-top-right{
  align-self: start;
  justify-self: end;
}
.column--align-middle-left{
  align-self: center;
  justify-self: start;
}
.column--align-middle-right{
  align-self: center;
  justify-self: end;
}
.column--align-bottom-left{
  align-self: end;
  justify-self: start;
}
.column--align-bottom-middle{
  align-self: end;
  justify-self: center;
}
.column--align-bottom-right{
  align-self: end;
  justify-self: end;
}



// Responsive Design - Size Variations

.grid__container--size-sm-12{
	@include breakpoint(sm){
		grid-column-end: span 12;
	}
}
.grid__container--size-sm-11{
	@include breakpoint(sm){
		grid-column-end: span 11;
	}
}
.grid__container--size-sm-10{
	@include breakpoint(sm){
		grid-column-end: span 10;
	}
}
.grid__container--size-sm-9{
	@include breakpoint(sm){
		grid-column-end: span 9;
	}
}
.grid__container--size-sm-8{
	@include breakpoint(sm){
		grid-column-end: span 8;
	}
}
.grid__container--size-sm-7{
	@include breakpoint(sm){
		grid-column-end: span 7;
	}
}
.grid__container--size-sm-6{
	@include breakpoint(sm){
		grid-column-end: span 6;
	}
}
.grid__container--size-sm-5{
	@include breakpoint(sm){
		grid-column-end: span 5;
	}
}
.grid__container--size-sm-4{
	@include breakpoint(sm){
		grid-column-end: span 4;
	}
}
.grid__container--size-sm-3{
	@include breakpoint(sm){
		grid-column-end: span 3;
	}
}
.grid__container--size-sm-2{
	@include breakpoint(sm){
		grid-column-end: span 2;
	}
}
.grid__container--size-sm-1{
	@include breakpoint(sm){
		grid-column-end: span 1;
	}
}


.grid__container--size-md-12{
	@include breakpoint(md){
		grid-column-end: span 12;
	}
}
.grid__container--size-md-11{
	@include breakpoint(md){
		grid-column-end: span 11;
	}
}
.grid__container--size-md-10{
	@include breakpoint(md){
		grid-column-end: span 10;
	}
}
.grid__container--size-md-9{
	@include breakpoint(md){
		grid-column-end: span 9;
	}
}
.grid__container--size-md-8{
	@include breakpoint(md){
		grid-column-end: span 8;
	}
}
.grid__container--size-md-7{
	@include breakpoint(md){
		grid-column-end: span 7;
	}
}
.grid__container--size-md-6{
	@include breakpoint(md){
		grid-column-end: span 6;
	}
}
.grid__container--size-md-5{
	@include breakpoint(md){
		grid-column-end: span 5;
	}
}
.grid__container--size-md-4{
	@include breakpoint(md){
		grid-column-end: span 4;
	}
}
.grid__container--size-md-3{
	@include breakpoint(md){
		grid-column-end: span 3;
	}
}
.grid__container--size-md-2{
	@include breakpoint(md){
		grid-column-end: span 2;
	}
}
.grid__container--size-md-1{
	@include breakpoint(md){
		grid-column-end: span 1;
	}
}



.grid__container--size-lg-12{
	@include breakpoint(lg){
		grid-column-end: span 12;
	}
}
.grid__container--size-lg-11{
	@include breakpoint(lg){
		grid-column-end: span 11;
	}
}
.grid__container--size-lg-10{
	@include breakpoint(lg){
		grid-column-end: span 10;
	}
}
.grid__container--size-lg-9{
	@include breakpoint(lg){
		grid-column-end: span 9;
	}
}
.grid__container--size-lg-8{
	@include breakpoint(lg){
		grid-column-end: span 8;
	}
}
.grid__container--size-lg-7{
	@include breakpoint(lg){
		grid-column-end: span 7;
	}
}
.grid__container--size-lg-6{
	@include breakpoint(lg){
		grid-column-end: span 6;
	}
}
.grid__container--size-lg-5{
	@include breakpoint(lg){
		grid-column-end: span 5;
	}
}
.grid__container--size-lg-4{
	@include breakpoint(lg){
		grid-column-end: span 4;
	}
}
.grid__container--size-lg-3{
	@include breakpoint(lg){
		grid-column-end: span 3;
	}
}
.grid__container--size-lg-2{
	@include breakpoint(lg){
		grid-column-end: span 2;
	}
}
.grid__container--size-lg-1{
	@include breakpoint(lg){
		grid-column-end: span 1;
	}
}



.grid__container--size-xl-12{
	@include breakpoint(xl){
		grid-column-end: span 12;
	}
}
.grid__container--size-xl-11{
	@include breakpoint(xl){
		grid-column-end: span 11;
	}
}
.grid__container--size-xl-10{
	@include breakpoint(xl){
		grid-column-end: span 10;
	}
}
.grid__container--size-xl-9{
	@include breakpoint(xl){
		grid-column-end: span 9;
	}
}
.grid__container--size-xl-8{
	@include breakpoint(xl){
		grid-column-end: span 8;
	}
}
.grid__container--size-xl-7{
	@include breakpoint(xl){
		grid-column-end: span 7;
	}
}
.grid__container--size-xl-6{
	@include breakpoint(xl){
		grid-column-end: span 6;
	}
}
.grid__container--size-xl-5{
	@include breakpoint(xl){
		grid-column-end: span 5;
	}
}
.grid__container--size-xl-4{
	@include breakpoint(xl){
		grid-column-end: span 4;
	}
}
.grid__container--size-xl-3{
	@include breakpoint(xl){
		grid-column-end: span 3;
	}
}
.grid__container--size-xl-2{
	@include breakpoint(xl){
		grid-column-end: span 2;
	}
}
.grid__container--size-xl-1{
	@include breakpoint(xl){
		grid-column-end: span 1;
	}
}
</style>